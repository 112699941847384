// 图片后缀名
 let img: string[] = [
  "bmp",
  "jpg",
  "jpeg",
  "png",
  "tif",
  "gif",
  "pcx",
  "tga",
  "exif",
  "fpx",
  "svg",
  "psd",
  "cdr",
  "pcd",
  "dxf",
  "ufo",
  "raw",
  "wmf",
  "webp",
]

export const imgEx = img.concat(img.map(i=>i.toUpperCase()));
// 在线文档后缀名
export let onlineEx: string[] = [
  // 音视频类
  'mp3',
  'wav',
  'mp4',
  'flv',
  'avi',
  'mov',
  'rm',
  'webm',
  'ts',
  'rm',
  'mkv',
  'mpeg',
  'ogg',
  'mpg',
  'rmvb',
  'wmv',
  '3gp',
  'ts',
  'swf',
  // doc类支持的后缀名
  "doc",
  "docx",
  "dotx",
  "fb2",
  "odt",
  "ott",
  "rtf",
  "txt",
  "pdf",
  "html",
  "epub",
  "xml",
  // xls类
  "xls",
  "xlsx",
  "xltx",
  "ods",
  "ots",
  "csv",
  // ppt类
  "ppt",
  "pptx",
  "potx",
  "odp",
  "otp",
];
// 只读文件后缀名
export let readonlyEx: string[] = ["pdf"];

export default {
  imgEx,
  onlineEx,
  readonlyEx,
};
