
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
@Component({
  name: "ShareModal",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class ShareModal extends Vue {
  public expireType: number | string = 0;
  public shareScope: number | string = 0;
  public shareName: String = "";
  public shareUrl: string = "";
  public accessPwd: string = "";
  public expireTypeShow: boolean = true;
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: "public" })
  readonly type!: string;
  @Prop({ default: false })
  readonly visible!: boolean;
  @Prop({ default: false })
  readonly loading!: boolean;
  @Prop({ required: true })
  readonly shareKey!: {
    shareName: string;
    shareUrl: string;
    accessPwd?: string;
  };
  @Prop({ default: false })
  readonly isTabs!: boolean;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("shareKey", { deep: true, immediate: true })
  shareKeyChange(val: any) {
    this.shareName = val.shareName;
    this.shareUrl =val.shareUrl;
    console.log('分享链接',val);
    
    this.accessPwd = val.accessPwd;
  }
  //ref 引用元素 Ref('ref')
  //@Ref()

  // 变更分享类型
  handleShareType(type: string): void {
    if(type !== this.type) this.$emit('typeChange',type)
  }
  // 关闭
  handleCancel(): void {
    this.$emit("close");
  }
  // 创建分享链接
  createShareUrl(): void {
    this.$emit("createShare", this.expireType,this.shareScope);
    this.expireTypeShow = false;
  }
  // 复制
  handleCopy(): void {
    var inp = document.createElement("input");
    if (this.expireTypeShow) {
      inp.value = this.shareUrl;
    } else {
      inp.value = `链接:${this.shareUrl} 密码:${this.accessPwd}`;
    }
    inp.style.opacity = "0";
    document.body.appendChild(inp);
    inp.select();
    document.execCommand("Copy");
    this.$message.success("复制成功");
    inp.remove();
  }


  // 复制(仅链接)
  handleCopyUrl(): void {
    var inp = document.createElement("input");
    // if (this.expireTypeShow) {
      inp.value = this.shareUrl;
    // } else {
    //   inp.value = `链接:${this.shareUrl} 密码:${this.accessPwd}`;
    // }
    inp.style.opacity = "0";
    document.body.appendChild(inp);
    inp.select();
    document.execCommand("Copy");
    this.$message.success("复制成功");
    inp.remove();
  }



  //创建前钩子函数
  created(): void {
    // alert('9999')
  }
}
