<template>
  <div class="report-officeOnline">
    <template v-if="platform !== 'mobile'">
      <a-tooltip
        :class="['star-office-btn', subjectColor]"
        placement="left"
        v-if="!isLogin"
      >
        <template slot="title"> 登录下载或保存 </template>
        <div class="button" @click="login">
          <span>登录</span>
        </div>
      </a-tooltip>

      <a-tooltip
        :class="['star-office-btn', subjectColor]"
        placement="left"
        v-if="isLogin && showSave"
      >
        <template slot="title"> 保存到我的星云文档 </template>
        <div class="button" @click="save">
          <span>保存</span>
        </div>
      </a-tooltip>

      <a-tooltip
        :class="['star-office-btn', subjectColor]"
        placement="left"
        v-if="isLogin && showShare"
      >
        <template slot="title"> 分享 </template>
        <div class="button" @click="share">
          <span>分享</span>
        </div>
      </a-tooltip>

      <!-- 登录 -->
      <a-modal
        :visible="loginShow"
        :closable="false"
        centered
        destroyOnClose
        @cancel="handleLoginShow(false)"
        :footer="null"
        width="520px"
        class="login-modal-wrap"
      >
        <Login class="login-modal" />
      </a-modal>

      <SaveModal
        v-if="saveModalShow"
        :visible="saveModalShow"
        type="save"
        :options="saveOptions"
        @close="saveModalShow = false"
        @save="saveOk"
      />

      <!-- 分享 -->
      <ShareModal
        v-if="shareVisible"
        :isTabs="true"
        :visible="shareVisible"
        :loading="shareLoading"
        :type="shareType"
        :shareKey="shareKey"
        @typeChange="changeShareType"
        @close="shareVisible = false"
        @createShare="handleExpire"
      />
    </template>

    <div id="placeholder"></div>
  </div>
</template>

<script>
import Vue from "vue";
// 不支持编辑的文件类型
import { readonlyEx } from "@/common/utils/fileEx";
import Login from "@/views/User/Login.vue";
import SaveModal from "@/views/popup/moveModal.vue";
import ShareModal from "@/views/mydoc/ShareModal.vue";

const xls = ["xls", "xlsx", "xltx", "ods", "ots", "csv"];
const ppt = ["ppt", "pptx", "potx", "odp", "otp"];
const odt = ["fb2", "odt", "ott", "rtf", "txt", "pdf", "html", "epub", "xml"];

export default Vue.extend({
  name: "officeOnline",
  components: {
    Login,
    SaveModal,
    ShareModal,
  },
  data() {
    return {
      subjectColor: "", // 主题色
      docEditor: null, //  文档编辑器
      platform: "desktop", //  查看平台
      documentKey: "",
      permissions: true, // 编辑权限
      mode: "view", // 文档查看/编辑
      isLogin: true, // 是否登录
      showSave: false, // 显示保存
      showShare: false, // 显示分享
      shareVisible: false,
      shareLoading: false,
      shareType: "public", // 分享类型
      expireType: 0, // 分享过期时间
      shareScope: 0, // 分享范围
      shareKey: {}, // 分享秘钥
      title: "",
      saveModalShow: false,
      saveOptions: { checkIdList: [] },
      appStore: {},
    };
  },
  computed: {
    loginShow() {
      return this.$store.state.share.officeLoginShow;
    },
  },
  created() {
    let query = this.$route.query;
    // 小程序端传入参数
    this.appStore = {
      loginTime: query.loginTime,
      userId: query.userId,
      name: query.name,
      companyId: query.companyId,
    };
    if (!this.$store.state.user.userId && !this.appStore.userId)
      this.isLogin = false;
    if (this.$route.query.moduleType === "share") {
      this.showSave = true;
    } else if (
      this.$route.query.moduleType !== "coop" &&
      this.$route.query.moduleType !== "history"
    ) {
      this.showShare = true;
    }
    if (query.fileName && query.fileExtension) {
      this.title = `${query.fileName}.${query.fileExtension}`;
      document.title = `${query.fileName}.${query.fileExtension}`;
    }
    if (xls.includes(query.fileExtension)) {
      this.subjectColor = "xls-color";
    } else if (ppt.includes(query.fileExtension)) {
      this.subjectColor = "ppt-color";
    } else {
      this.subjectColor = "doc-color";
    }
    this.judgePlatform();
    // this.closeCallback();
    // this.ShowMonitorInfo();
  },
  mounted() {
    this.$nextTick(() => {
      this.setStatus();
    });
    // 页面关闭时调接口强制保存
    // window.addEventListener('beforeunload', (e) => this.forceSave(e))
    document.addEventListener("visibilitychange", this.hiddenCallback);
  },
  destroyed() {
    this.toDestroy();
    document.removeEventListener("visibilitychange", this.hiddenCallback);
    // window.removeEventListener('beforeunload', (e) => this.forceSave(e))
  },
  beforeRouteLeave() {
    // window.removeEventListener('beforeunload', (e) => this.forceSave(e))
  },
  methods: {
    hiddenCallback() {
      if (document.hidden && this.$route.query.ot === "edit") {
        // 页面被挂起
        this.$apis.officeCallback();
      }
    },
    // 显示监控信息
    ShowMonitorInfo() {
      let header;
      if (this.platform === "mobile") {
        header = {
          channelType: this.$route.query.moduleType === "share" ? "web" : "app",
          userId: this.appStore.userId,
          userName: encodeURI(this.appStore.name),
          loginTime: this.appStore.loginTime,
        };
      }
      this.$apis.officeShowMonitorInfo({}, header).then((res) => {
        try {
          console.log(res);
          sessionStorage.setItem("monitorInfo", JSON.stringify(res));
        } catch (error) {
          console.error(error);
        }
      });
    },
    // 登录
    handleLoginShow(show) {
      if (show) this.$message.warning("该功能需要登录,请登录后操作");
      this.$store.commit("share/SET_OFFICELOGINSHOW", show);
    },
    // 保存
    save() {
      this.saveModalShow = true;
      this.saveOptions.checkIdList = [this.$route.query.userFileId];
    },
    // 登录
    login() {
      this.$store.commit("share/SET_OFFICELOGINSHOW", true);
    },
    // 分享
    share() {
      this.shareFileVisible = false;
      this.shareOk();
    },
    // 生成分享链接
    shareOk() {
      let params = {
        shareType: this.shareType,
        // shareUrl: location.origin + "/#/fileShare",
        userFileIds: [this.$route.query.userFileId],
        expireType: this.shareType === "public" ? undefined : this.expireType,
        scope: this.shareType === "public" ? undefined : this.shareScope,
        shareName: this.title,
        companyId: "person",
      };
      this.shareVisible = true;
      this.shareLoading = true;
      this.$apis.shareCreateShare(params).then((res) => {

        
        // 嵌入到主应用里去了，这里要加入微应用的路由
        this.shareKey = {
          ...res.data,
          shareUrl: `${process.env.VUE_APP_FILE_VIVW}/star-pan-web/fileShare?shareKey=${res.data.shareUrl}&scope=ALL`,
          shareName: this.title,
        };
        this.shareLoading = false;
      });
    },
    // 切换分享类型
    changeShareType(type) {
      this.shareType = type;
    },
    // 分享过期时间设置和分享范围设置
    handleExpire(value, item) {
      this.expireType = value;
      this.shareScope = item ? "REGISTERED" : "ALL";
      this.shareOk();
    },
    saveOk(targetId, sourceIds) {
      console.log(targetId, sourceIds);
      this.$apis
        .userFileCopy({
          targetId,
          sourceIds,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("文件保存成功");
            this.saveModalShow = false;
            this.$apis.shareRecord({
              fileName: this.title,
              shareId: this.$route.query.shareId,
              type: "forward",
            });
          }
        });
    },
    setStatus() {
      // 待拓展
      switch (this.$route.query.ot) {
        case "edit":
          this.showDocDetail(true);
          break;
        default:
          this.showDocDetail();
      }
    },
    initDocEditor(docserviceApiUrl, config) {
      console.log(config);
      const that = this;
      // this.loadOfficeOnlineAPI(docserviceApiUrl).then(() => {
      //   /* global DocsAPI */
      //   this.docEditor = new DocsAPI.DocEditor("placeholder", {
      //     ...config,
      //     editorConfig: {
      //       ...config.editorConfig,
      //       lang: "zh", //  语言设置为中文
      //       mode: this.mode,
      //       customization: {
      //         // ...config.editorConfig.customization,
      //         zoom: 100, //  缩放比例为 100
      //         hideRightMenu: true,
      //         spellcheck: false,
      //       },
      //     },
      //     events: {
      //       // 点击编辑
      //       onRequestEditRights(e) {
      //         if (!that.$store.state.user.userId && !that.appStore.userId) {
      //           that.handleLoginShow(true);
      //         } else if (that.$route.query.moduleType === "share") {
      //           that.$message.warning("分享的文件无法编辑");
      //         } else if (that.$route.query.moduleType === "history") {
      //           that.$message.warning("历史文件无法编辑");
      //         } else if (!that.permissions) {
      //           that.$message.warning("没有编辑权限,请联系协作发起人！");
      //         } else {
      //           if (that.$route.query.moduleType === "team") {
      //             that.$apis.companyTeamDynamicAdd({
      //               operateType: "UPDATE",
      //               operatedObjectId: that.$route.query.userFileId,
      //               teamId: that.$route.query.teamId,
      //             });
      //           }
      //           that.toEdit();
      //         }
      //       },
      //       // 文档加载完成
      //       onDocumentReady(e) {
      //         console.log("文档加载完成");
      //         // 编辑禁用
      //         // that.docEditor.denyEditingRights()
      //       },
      //       /* // 历史版本操作
      //       onRequestHistory() {

      //       }, */
      //       // 发生错误
      //       onError(e) {
      //         console.error("发生错误", e);
      //       },
      //       // 出现警告
      //       onWarning(e) {
      //         console.warn("出现警告", e);
      //       },
      //     },
      //   });
      // });
    },
    // 平台判断
    judgePlatform() {
      this.platform = "desktop"; // 浏览平台
      if (
        /AppleWebKit.*Mobile/i.test(navigator.userAgent) ||
        /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(
          navigator.userAgent
        )
      ) {
        if (window.location.href.indexOf("?mobile") < 0) {
          try {
            if (
              /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
            ) {
              this.platform = "mobile";
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    // 预览/编辑文档
    showDocDetail(canEdit = false) {
      let query = this.$route.query;
      let data = {
        coopId: query.coopId,
        userFileId: query.userFileId,
        parentFileId: query.parentFileId ? query.parentFileId : undefined,
        versionNum: query.versionNum,
        moduleType: query.moduleType || "doc",
      };
      if (data.moduleType === "share") {
        data.userId =
          this.$store.state.user.userId || this.appStore.userId || "0";
        data.userName =
          this.$store.state.user.name || this.appStore.name || "游客";
      }
      let apiName = canEdit ? "officeEdit" : "officeView";
      let header;
      if (this.platform === "mobile") {
        header = {
          channelType: data.moduleType === "share" ? "web" : "app",
          userId: this.appStore.userId,
          userName: encodeURI(this.appStore.name),
          loginTime: this.appStore.loginTime,
        };
      }
      this.$apis[apiName](data, header).then((res) => {
        // this.permissions = res.document.permissions.edit;
        this.mode = res.mode;
        // this.documentKey = res.document.key;
        let config = {
          ...res,
          type: this.platform,
          editorConfig: res.documentEditParam,
        };
        // 除了分享和历史,默认开放编辑按钮,权限校验走编辑事件
        // if (
        //   query.moduleType !== "share" &&
        //   query.moduleType !== "history" &&
        //   this.platform !== "mobile" &&
        //   !odt.includes(query.fileExtension)
        // ) {
        //   config.document.permissions.edit = true;
        // } else {
        //   config.document.permissions.edit = false;
        // }
        // config.document['permissions'].edit = false
        this.initDocEditor(res.officeApiUrl, config);
      });
    },
    // body插入外部js
    // loadOfficeOnlineAPI(src) {
    //  console.log('----src',src);
    //   return new Promise((resolve, reject) => {
    //     const script = document.createElement("script");
    //     script.type = "text/javascript";
    //     script.src = src;
    //     document.body.appendChild(script);
    //     script.onload = () => {
    //       resolve(0);
    //     };
    //     script.onerror = () => {
    //       reject();
    //     };
    //   });
    // },
    toEdit() {
      if (readonlyEx.includes(this.$route.query.fileExtension)) {
        this.$message.warning(
          `${this.$route.query.fileExtension}文件不支持编辑`
        );
      } else {
        // 跳编辑页
        let query = {
          ...this.$route.query,
          userId: this.$store.state.user.userId || this.appStore.userId,
          userName: this.$store.state.user.name || this.appStore.name,
          ot: "edit",
        };
        this.$router.push({
          name: "VIEW_OFFICE_ONLINE",
          query,
        });
      }
    },
    toDestroy() {
      this.docEditor && this.docEditor.destroyEditor();
    },
    // closeCallback() {
    //   const that = this;
    //   window.onbeforeunload = function (ev) {
    //     that.$apis.officeClose({
    //       fileName: that.title,
    //       type: that.$route.query.ot,
    //       userId: that.$store.state.user.userId || that.appStore.userId,
    //     });
    //   };
    // },
    // async forceSave(e: any) {
    //     if (e) {
    //         e.returnValue = '确认关闭页面'
    //     }
    //     await this.$apis.officeForceSave({
    //         c: 'forcesave',
    //         documentKey: this.documentKey,
    //         userName: this.$store.state.user.name,
    //         user: {
    //             id: this.$store.state.user.userId,
    //             name: this.$store.state.user.name,
    //         },
    //     })
    // },
  },
  // watch: {
  //   "$route.query.ot": function (ot, oldValue) {
  //     this.$apis
  //       .officeClose({
  //         fileName: this.title,
  //         type: oldValue,
  //         userId: this.$store.state.user.userId || this.appStore.userId,
  //       })
  //       .then((res) => {
  //         this.ShowMonitorInfo();
  //       });
  //     // 文件编辑器销毁重绘
  //     this.toDestroy();
  //     this.setStatus();
  //   },
  // },
});
</script>

<style lang="less">
.report-officeOnline {
  position: relative;
  height: 100vh;
  width: 100vw;

  [name="frameEditor"] {
    display: block;
  }

  .star-office-btn {
    position: fixed;
    height: 50px !important;
    width: 50px;
    border-radius: 50%;
    line-height: 50px;
    bottom: 50px;
    right: 50px;
    z-index: 10;
    color: #5764ff;
    cursor: pointer;

    span {
      display: block;
      width: 50px;
      text-align: center;
      font-size: 14px;
      letter-spacing: 2px;
      padding-left: 2px;
    }
  }

  .xls-color {
    color: #fff;
    background-color: #40865c;
  }

  .ppt-color {
    color: #fff;
    background-color: #aa5252;
  }

  .doc-color {
    color: #fff;
    background-color: #446995;
  }

  .xls-color:hover {
    background-color: #286942;
  }

  .ppt-color:hover {
    background-color: #8a3333;
  }

  .doc-color:hover {
    background-color: #2c4f7a;
  }
}

.login-modal-wrap {
  .ant-modal {
    border-radius: 8px;
    padding: 0;
    overflow: hidden;

    .ant-modal-body {
      padding: 0;
    }
  }

  .login-modal {
    min-width: 520px;
    height: 500px;
    background-color: #fff;
    text-align: center;
  }
}

#app .mainContent {
  height: 100%;
  width: 100%;
}
</style>
